import { render, staticRenderFns } from "./mywarung_detail.vue?vue&type=template&id=62225ffc&scoped=true"
import script from "./mywarung_detail.vue?vue&type=script&lang=js"
export * from "./mywarung_detail.vue?vue&type=script&lang=js"
import style0 from "./mywarung_detail.vue?vue&type=style&index=0&id=62225ffc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62225ffc",
  null
  
)

export default component.exports