<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-body">
                    <div class="container">
                        <h3 class="uk-text-center uk-text-bolder">Download Report</h3>
                        <div>
                            <datetime
                                id="start_date"
                                input-class="uk-button"
                                v-model="start_date"
                                format="dd LLL yyyy"
                                value-zone="local"
                                :max-datetime="new Date().toISOString()"
                                placeholder="Start Date"
                            ></datetime>
                        </div>
                        <template v-if="start_date">
                            <div class="uk-text-center uk-margin-auto-vertical">-</div>
                            <div>
                                <datetime
                                    id="end_date"
                                    input-class="uk-button"
                                    v-model="end_date"
                                    :min-datetime="start_date"
                                    format="dd LLL yyyy"
                                    value-zone="local"
                                    :max-datetime="new Date().toISOString()"
                                    placeholder="End Date"
                                ></datetime>
                            </div>
                            <div class="uk-margin-top">
                                <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-1" type="button" @click="download">
                                    Download
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Datetime } from 'vue-datetime';

export default {
    data(){
        return {
            start_date: null,
            end_date: null
        };
    },
    components: {
        Datetime
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        download() {
            this.$emit('download', {start_date: this.start_date, end_date: this.end_date});
            this.hideModal(false);
        }
    }
};
</script>

<style scoped>

</style>
