<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Top-Up Balance</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Please enter the desired amount</p>
                    <label class="uk-form-label" style="display: block">Added balance <b class="uk-text-danger">*</b></label>
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" style="background-color: #E5E5E5; color: #343434;">Rp</span>
                        <input
                            style="padding-left: 50px !important"
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('added_balance')}"
                            name="added_balance"
                            type="text"
                            v-model="added_balance"
                            v-validate="'required'"
                            @keyup="formatText"
                        />
                    </div>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('added_balance')">{{ errors.first('added_balance') }}</span>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <section v-if="isLoading">
                        <button class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    </section>
                    <section v-else>
                        <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="topup">Save</button>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data(){
        return {
            added_balance: 0,
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        ...mapActions({
            topupWarungBalance: 'warung/topupWarungBalance',
        }),
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        formatText() {
            const balance = this.added_balance.replace(/[^0-9]/g, '');
            this.added_balance = formatter.thousandSeparator(balance);
        },
        async topup() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                const balance = this.added_balance.toString().replace(/[^0-9]/g, '');
                const response = await this.topupWarungBalance({
                    id: this.$route.params.warung_id, formData: { topup_balance: balance }
                });

                if (response && response.status === 'OK') {
                    this.isLoading = false;
                    notificationSuccess('Topup balance successful!');
                    this.hideModal(true);
                } else {
                    this.isLoading = false;
                    notificationDanger('Failed to topup!');
                }
            } catch (err) {
                this.isLoading = false;
                notificationDanger(err);
            }
        },
    }
};
</script>
