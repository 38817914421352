<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-body">
                    <div class="container">
                        <h3 class="uk-text-center uk-text-bolder">{{ data.name }}</h3>
                        <p class="uk-text-center">{{ data.address }}</p>
                        <div class="child uk-border-rounded">
                            <vue-qrcode
                                style="border-radius: 5px;"
                                ref="qr"
                                :value="'mywarung:' + data._id"
                                :options='{ width: 240, color: { dark: "#000000", light: "#FFFFFF"} }'
                            ></vue-qrcode>
                        </div>
                        <div class="uk-text-center uk-margin-medium-top">
                            <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="createPDF(data)">
                                <img :src="`${images}/download.svg`" alt="" width="20" class="uk-margin-small-right" />
                                Download QR Code (.pdf)
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import jsPDF from 'jspdf';
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            images: PREFIX_IMAGE,
        };
    },
    components: {
        VueQrcode: () => import('@chenfengyuan/vue-qrcode')
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        createPDF (item) {
            const doc = new jsPDF({ format: 'a5' });
            const contentHtml = this.$refs.qr.$el;
            const image = contentHtml.toDataURL('image/jpeg', 1);
            doc.addImage(image, 'JPEG', 25, 65, 100, 100);

            // corner
            let corner = new Image();
            corner.src = `${this.images}/qr-mywarung/qr-image-corner.png`;
            doc.addImage(corner, 'PNG', 0, 0);
            doc.addImage(corner, 'PNG', 148, 124, null, null, null, null, 180);
            // logo
            let mywarung = new Image();
            mywarung.src = `${this.images}/qr-mywarung/qr-text-mywarung.png`;
            doc.addImage(mywarung, 'PNG', 90, 7);

            // title
            doc.setFontSize(36);
            doc.setFont(undefined, 'bold');
            doc.setTextColor('#0ABAB5');
            doc.text('Pembayaran', 75, 40, { align: 'center' });
            let decoration = new Image();
            decoration.src = `${this.images}/qr-mywarung/qr-text-sunray.png`;
            doc.addImage(decoration, 'PNG', 28, 23);

            // warung name
            doc.setFontSize(17);
            doc.setFont(undefined, 'bold');
            doc.setTextColor('#000000');
            doc.text(item.name, 75, 55, { align: 'center' });
            // warung address
            doc.setFontSize(15);
            doc.setFont(undefined, 'none');
            doc.setTextColor('#757575');
            doc.text(item.address, 75, 65, { align: 'center' });

            // bottom text
            doc.setFontSize(25);
            doc.setFont(undefined, 'bold');
            doc.setTextColor('#FEBE10');
            doc.text('Yuk, catat total belanja kamu!', 75, 175, { align: 'center' });

            doc.save('mywarung:' + item._id + '.pdf');
        },
    }
};
</script>

<style scoped>
.qr-code {
    height: auto;
    width: 50rem;
}
.container {
    margin: auto;
    width: 400px;
    height: auto;
    padding: 20px;
}
.container button {
    width: 60%;
    font-size: 12px;
}
.child {
    width: 220px;
    height: auto;
    /*outline: dashed 1px black;*/
    /* Center horizontally*/
    margin: 0 auto;
    padding: 10px 10px;
    background: #0C9389;
}
</style>
