<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <h3>Warung details</h3>
            </div>
            <div class="uk-flex uk-flex-right">
                <button
                    class="uk-close-large"
                    style="color: black;"
                    type="button"
                    uk-close
                    @click="backToWarungList()"
                ></button>
            </div>
        </div>
        <div v-if="isLoading" uk-spinner class="uk-position-center" :ratio="2"></div>
        <div v-else class="uk-flex uk-flex-between uk-margin" uk-grid>
            <div class="uk-width-1-2">
                <div class="uk-width-expand uk-margin-auto uk-margin-auto-vertical">
                    <span class="uk-text-bold">{{ warung_data.name }}</span><br>
                    <span>
                        <img :src="`${images}/location_bl.svg`" alt="Location icon" />
                        {{ warung_data.address }}
                    </span>
                    <br><br>
                </div>
                <div class="uk-flex uk-flex-left">
                    <button
                        class="uk-button uk-button-primary uk-border-rounded uk-margin-small-right"
                        style="padding: 0 10px;"
                        type="button"
                        @click="showModal('qr')"
                    >
                        <img :src="`${images}/qr_white.svg`" alt="QR icon" />
                        Show QR Code
                    </button>
                    <button
                        class="uk-button uk-border-rounded uk-margin-small-right"
                        style="background-color: #EBB652; color: #fff; padding: 0 10px;"
                        type="button"
                        @click="$router.push(`${$route.params.warung_id}/assign`)"
                    >
                        <img :src="`${images}/add-mitra_white.svg`" alt="Assign mitra icon" />
                        Assign Mitra
                    </button>
                    <button
                        class="uk-button uk-border-rounded"
                        style="background-color: #009688; color: #fff; padding: 0 10px;"
                        type="button"
                        @click="showModal('prod')"
                    >
                        <img :src="`${images}/add-item_white.svg`" alt="Add product icon" />
                        Add Types of Goods
                    </button>
                </div>
            </div>
            <div class="uk-width-1-3">
                <p class="uk-text-right" style="color: #0ABAB5;cursor: pointer;" @click="showDateModal = true">
                    <img :src="`${images}/report-download.svg`" alt="Download report icon" />
                    Download Report
                </p>
                <div
                    class="uk-panel uk-border-rounded uk-padding-small"
                    :class="[warung_balance > 0 ? 'positive-balance' : warung_balance === 0 ? 'zero-balance' : 'negative-balance']"
                >
                    <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-small-bottom">
                        <span>
                            <img :src="`${images}/wallet_bl.svg`" alt="Wallet icon" />
                            Balance left:
                        </span>
                        <span class="uk-text-large uk-text-bold">
                            <img v-if="warung_balance === 0" :src="`${images}/alert_yl.svg`" alt="Yellow alert icon" />
                            <span v-if="warung_balance < 0"><img :src="`${images}/alert_red.svg`" alt="Red alert icon" />-</span>
                            Rp{{ formatter.thousandSeparator(warung_balance) }}
                        </span>
                    </div>
                    <button
                        class="uk-block uk-button uk-border-rounded"
                        style="background-color: #0ABAB5; color: #fff; width: 100%;"
                        type="button"
                        @click="showModal('topup')"
                    >
                        <img :src="`${images}/wallet-plus_white.svg`" alt="Topup icon" />
                        Top-Up Balance
                    </button>
                </div>
            </div>
        </div>

        <div class="uk-grid">
            <div class="uk-width-1-3">
                <div class="uk-text-large">Types of Goods List</div>
                <div class="uk-card uk-card-default uk-margin">
                    <div class="uk-overflow-auto uk-margin-top">
                        <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                            <thead>
                                <tr class="uk-table-middle">
                                    <th class="uk-table-expand">Types of Goods</th>
                                    <th class="uk-text-center uk-table-shrink">Action</th>
                                </tr>
                            </thead>
                            <loading-table v-if="productLoad" :colspan="2"/>
                            <tbody v-else-if="productList.length>0" class="uk-table-middle">
                                <tr v-for="(prod, i) in productList" :key="i">
                                    <td>{{ prod.name }}</td>
                                    <td>
                                        <button class="uk-button uk-button-small uk-button-default" type="button" :disabled="!consist(user_cred.roles, ['admin-bd','qwe123'])">Actions</button>
                                        <div uk-dropdown="mode: click">
                                            <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                <li><a @click="showModal('prod', prod)">Edit</a></li>
                                                <li><a @click="deleteItem('product', prod)">Delete</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table v-else :colspan="6" />
                        </table>
                    </div>
                </div>
            </div>

            <div class="uk-width-2-3">
                <div class="uk-text-large">Transaction History</div>
                <div class="uk-card uk-card-default uk-margin">
                    <div class="uk-overflow-auto uk-margin-top">
                        <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                            <thead>
                                <tr class="uk-table-middle">
                                    <th class="">Dates</th>
                                    <th class="">Types of Goods</th>
                                    <th class="">Name of Goods</th>
                                    <th class="uk-table-shrink">Total Amount of Spending</th>
                                    <th class="uk-width-auto">Buyer's Name</th>
                                </tr>
                            </thead>
                            <loading-table v-if="historyLoad" :colspan="6"/>
                            <tbody v-else-if="transactions && transactions.docs.length>0" class="uk-table-middle">
                                <tr v-for="(tr, i) in transactions.docs" :key="i">
                                    <td>{{ moment(tr.created_at).format('DD MMM YYYY') }}</td>
                                    <td>{{ tr.warung_product_id.name }}</td>
                                    <td>{{ tr.description }}</td>
                                    <td>Rp{{ formatter.thousandSeparator(tr.amount) }}</td>
                                    <td v-if="userDetailLoad"><div uk-spinner></div></td>
                                    <td v-else><a @click="$router.push(`${$route.params.warung_id}/transaction/${tr.user_id}`)">{{ tr.fullname }}</a></td>
                                </tr>
                            </tbody>
                            <empty-table v-else :colspan="6" />
                        </table>
                    </div>
                </div>
                <pagination
                    v-if="transactions"
                    :total-data="transactions.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
        </div>

        <modify-product v-if="showProductModal" :data="formProduct" @hideModal="hideProductModal" />
        <qr-warung v-if="showQrModal" :data="warung_data" @hideModal="showQrModal = false" />
        <top-up-warung v-if="showTopupModal" @hideModal="hideTopupModal" />
        <confirm-delete-modal v-if="showConfirmDeleteModal" :data="deleteProduct" @hideModal="afterDelete" @confirmDelete="confirmDelete" />
        <date-modal v-if="showDateModal" @hideModal="showDateModal = false" @download="downloadReport" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import ModifyProduct from '@/components/modals/community/mywarung/modify_product';
import QrWarung from '@/components/modals/community/mywarung/qr_warung';
import TopUpWarung from '@/components/modals/community/mywarung/topup_warung';
import ConfirmDeleteModal from '@/components/globals/modals/confirm_delete';
import DateModal from '@/components/modals/community/mywarung/date';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from '@/utils/constant';
import { notificationSuccess, notificationDanger } from '@/utils/notification';
import moment from 'moment';
moment.locale('id');
import { excelDownloader } from '@/utils/helper';

export default {
    data(){
        return {
            moment: moment,
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            user_details: {},
            formatter,
            meta: {
                limit: 50,
                page: 1
            },
            warung_data: null,
            warung_balance: 0,
            productList: [],
            transactions: null,
            isLoading: true,
            productLoad: false,
            historyLoad: false,
            assigned_mitra: [],
            dataUser: null,
            dataPartnership: null,
            userDetailLoad: true,
            showProductModal: false,
            formProduct: {
                id: null,
                name: ''
            },
            showDateModal: false,
            showQrModal: false,
            showTopupModal: false,
            showModifyTransactionModal: false,
            showReportModal: false,
            showConfirmDeleteModal: false,
            deleteProduct: {
                title: 'Are you sure to delete this Product?',
                _id: null,
                body: null,
            },
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        QrWarung,
        TopUpWarung,
        ModifyProduct,
        ConfirmDeleteModal,
        DateModal
    },
    watch: {
        meta() {
            this.getWarungTransactionList();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.deleteModal')) window.UIkit.modal('.deleteModal').$destroy(true);
    },
    async mounted() {
        this.isLoading = true;
        await Promise.all([
            this.getDetailWarungData(),
            this.getWarungProductList(),
            this.getWarungTransactionList(),
        ]);

        if(!this.productLoad && !this.historyLoad) {
            this.isLoading = false;
        }

        this.getAllData();
    },
    methods: {
        ...mapActions({
            getDetailWarung: 'warung/getDetailWarung',
            getWarungTransaction: 'warung/getWarungTransaction',
            getWarungProducts: 'warung/getGoods',
            deleteWarungProduct: 'warung/deleteWarungProduct',
            getAllAssignedMitra: 'warung/getAllAssignedMitra',
            getUserFullname: 'internal_api/getUserFullname',
            getUserPartnership: 'internal_api/getUserPartnership',
            downloadReportWarungTransaction: 'warung/downloadReportWarungTransaction',
        }),
        async getAllData() {
            const assigned_mitra_data = await this.getAllAssignedMitra({ id: this.$route.params.warung_id });
            this.assigned_mitra = [];
            for (const cust of assigned_mitra_data) {
                this.assigned_mitra.push(cust.user_id);
            }
            [this.dataUser, this.dataPartnership] = await Promise.all([
                this.getUserFullname({user_ids: this.assigned_mitra}),
                this.getUserPartnership({user_ids: this.assigned_mitra})
            ]);
        },
        async getUserFullnameData(){
            this.userDetailLoad = true;
            const user_ids = [];
            for (const trans of this.transactions.docs) {
                user_ids.push(trans.user_id);
            }
            const data = await this.getUserFullname({user_ids: user_ids});
            for (const trans of this.transactions.docs) {
                const user = data.result.find((data) => data._id.toString() === trans.user_id.toString());
                if (user) trans.fullname = user.fullname;
            }
            this.userDetailLoad = false;
        },
        async getDetailWarungData(){
            const data = await this.getDetailWarung(this.$route.params.warung_id);
            this.warung_data = data.warung;
            this.warung_balance = data.balance;
        },
        async getWarungProductList(){
            this.productLoad = true;
            this.productList = await this.getWarungProducts(this.$route.params.warung_id);
            this.productLoad = false;
        },
        async getWarungTransactionList(){
            this.historyLoad = true;
            this.transactions = await this.getWarungTransaction({id: this.$route.params.warung_id, formData: this.meta});
            if (this.transactions && this.transactions.docs.length > 0) {
                await this.getUserFullnameData();
            }
            this.historyLoad = false;
        },
        async getDataDetailWarung(){
            const warung = await this.getDetailWarung(this.$route.params.warung_id);
            if (warung && warung.warung) {
                this.warung_name = warung.warung.name;
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        showModal(type, data = null){
            if (data) {
                if (type == 'prod') {
                    this.formProduct.id = data._id;
                    this.formProduct.name = data.name;
                } else if (type == 'tr') {
                    this.formTransaction = data;
                }
            }
            switch(type) {
            case 'qr':
                this.showQrModal = true;
                break;
            case 'prod':
                this.showProductModal = true;
                break;
            case 'topup':
                this.showTopupModal = true;
                break;
            case 'tr':
                this.showModifyTransactionModal = true;
                break;
            case 'report':
                this.showReportModal = true;
                break;
            }
        },
        deleteItem(type, data) {
            this.showConfirmDeleteModal = true;
            this.onDelete = type;
            if (type == 'product') {
                this.deleteProduct._id = data._id;
                this.deleteProduct.body = {
                    "Product Name": data.name
                };

                this.deleteProduct.isShow = true;
            }
        },
        hideProductModal(isRefresh){
            if (isRefresh) this.getWarungProductList();
            this.showProductModal = false;
            this.formProduct = {
                id: null,
                name: ''
            };
        },
        hideTopupModal(isRefresh){
            if (isRefresh) this.$router.go();
            this.showTopupModal = false;
        },
        afterDelete(isRefresh) {
            if (this.onDelete == 'product') {
                if (isRefresh) this.getWarungProductList();
                this.deleteProduct._id = null;
                this.deleteProduct.body = null;
            }
            this.showConfirmDeleteModal = false;
        },
        async confirmDelete(id) {
            let response;
            if (this.onDelete == 'product') {
                const payload = {
                    w_id: this.$route.params.warung_id,
                    p_id: id
                };
                response = await this.deleteWarungProduct(payload);
            }
            if (response && response.status === 'OK') {
                notificationSuccess('Product deleted!');
                this.afterDelete(true);
            } else {
                notificationDanger('Failed to delete product!');
                this.afterDelete(false);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async getUserDataExcel(dataChange){
            if (this.dataUser && this.dataPartnership) {
                for (const pg_data of dataChange) {
                    const [partner, user] = await Promise.all([
                        this.dataPartnership.result.find((data) => data.user_id.toString() === pg_data["User ID"].toString()),
                        this.dataUser.result.find((data) => data._id.toString() === pg_data["User ID"].toString()),
                    ]);
                    pg_data["Date"] = moment(pg_data["Date"]).format('DD-MM-YYYY');
                    if (partner) {
                        pg_data["Company"] = partner.company_id.name ?? "-";
                        pg_data["Office"] = partner.company_office_id.name ?? "-";
                        pg_data["Role"] = partner.role_id.name ?? "-";
                    } else {
                        pg_data["Company"] = "-";
                        pg_data["Office"] = "-";
                        pg_data["Role"] = "-";
                    }
                    if (user) {
                        pg_data["Fullname"] = user.fullname ?? "-";
                        pg_data["Email"] = user.email ?? "-";
                        pg_data["Phone Number"] = user.phone_number ?? "-";
                    } else {
                        pg_data["Fullname"] = "-";
                        pg_data["Email"] = "-";
                        pg_data["Phone Number"] = "-";
                    }
                }
            }
            return dataChange;
        },
        async downloadReport(data) {
            this.isLoadingButton = true;

            const response = await this.downloadReportWarungTransaction({
                id: this.$route.params.warung_id,
                start_date: data.start_date,
                end_date: data.end_date
            });
            response.result = await this.getUserDataExcel(response.result);
            if (response && response.status === 'OK') {
                excelDownloader(response.result, response.filename);
                notificationSuccess('Downloaded!');
            } else {
                notificationDanger(response.message);
            }

            this.isLoadingButton = false;
        },
        backToWarungList() {
            const searchKey = this.$route.query.keywords;
            if (searchKey) {
                this.$router.push(`/admin/community/mywarung?keywords=${searchKey}`);
            } else {
                this.$router.push(`/admin/community/mywarung`);
            }
        },
    }
};
</script>

<style scoped>
.positive-balance{
    background-color: rgba(10, 186, 181, 0.1);
    border: solid 2px #0ABAB5;
}
.zero-balance{
    background-color: rgba(254, 190, 16, 0.1);
    border: solid 2px #FEBE10;
}
.negative-balance{
    background-color: rgba(216, 2, 15, 0.1);
    border: solid 2px #D8020F;
}
</style>
