<template>
    <section>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
                <div class="uk-modal-header">
                    <h2 v-if="data.id" class="uk-modal-title">Edit Type of Goods</h2>
                    <h2 v-else class="uk-modal-title">Add New Type of Goods</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Please enter the name of goods below</p>
                    <label class="uk-form-label">Type of Goods <b class="uk-text-danger">*</b></label>
                    <input
                        class="uk-input"
                        :class="{'uk-form-danger': errors.has('name')}"
                        name="name"
                        type="text"
                        v-model="productName"
                        v-validate="'required'">
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <section v-if="isLoading">
                        <button class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    </section>
                    <section v-else>
                        <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            productName: '',
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
        if (this.data.id) {
            this.productName = this.data.name;
        }
    },
    methods: {
        ...mapActions({
            addWarungProduct: 'warung/addWarungProduct',
            editWarungProduct: 'warung/editWarungProduct',
        }),
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;
                const response = this.data.id
                    ? await this.editWarungProduct({
                        id: { w_id: this.$route.params.warung_id, p_id: this.data.id }, formData: { name: this.productName }
                    })
                    : await this.addWarungProduct({
                        id: this.$route.params.warung_id, formData: { name: this.productName }
                    });

                if (response && response.status === 'OK') {
                    notificationSuccess('New product added!');
                    this.hideModal(true);
                } else {
                    notificationDanger('Failed to add!');
                    this.hideModal(false);
                }
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
                notificationDanger(err);
            }
        },
    }
};
</script>
